import React, { useState, useEffect } from "react";

import { swrFetcher } from "../../utils/request";

import CartesNoPub from "../cartes/noPub";
import Loading from "../loading";

import styles from "./listeRelated.module.scss";
import { Recette } from "../../types/recette";
import { Publication } from "@/types/publication";
import { useInView } from "react-intersection-observer";

type ListeRelatedProps = {
    title?: string;
    type?: string;
    items?: Recette[] | Publication[];
};

const ListeRelated = ({ title, type, items }: ListeRelatedProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fallback, setFallback] = useState([]);
    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        const controller = new AbortController();

        if (!items || items?.length === 0) {
            setIsLoading(true);
            const request = swrFetcher("/api/accueil/populaires", controller);
            
            if (!isLoading) return
            request.then((data) => {
                const items = data?.items || data;
                if (items?.length > 0) {
                    setFallback(items?.slice(0, 3));
                }
                setIsLoading(false);
            });
        }

        return () => {
            setIsLoading(false);
            if (controller.signal.aborted) {
                controller.abort();
            }
        };
    }, [items]);

    if (isLoading) {
        return (
            <div className={styles.me}>
                <Loading />
            </div>
        );
    }

    return (
        <div ref={ref} data-component="ListeRelated">
            {inView && items && items?.length > 0 ? (
                <div className={styles.me}>
                    <div className="body">
                        <h2>{title || `Vous allez adorer ces ${type}s`}</h2>
                        <CartesNoPub contenus={items} />
                    </div>
                </div>
            ) : (
                fallback?.length > 0 && (
                    <div className={styles.me}>
                        <div className="body">
                            <h2>{title || `Vous allez adorer ces ${type}s`}</h2>
                            <CartesNoPub contenus={fallback} />
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default ListeRelated;
