import React from "react";
import { Recette } from "@/types/recette";
import { Publication } from "@/types/publication";

import Carte from "./carte";

import styles from "./cartes.module.scss";

type PropTypes = {
    contenus: Recette[] | Publication[];
};

const CartesNoPub = ({ contenus }: PropTypes) => (
    <div className={styles.me}>
        {contenus.map((contenu) => {
            const { id } = contenu;
            return <Carte key={id} contenu={contenu} />;
        })}
    </div>
);

export default CartesNoPub;
