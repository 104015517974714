/* eslint-disable @next/next/no-img-element */
import React from "react";
import classNames from "classnames";
import Head from "next/head";

import { useTimeoutSimple } from "../../hooks/useTimeout";
import Layout from "../../layout";
import ListeRelated from "../../ui/listeRelated";

import styles from "./notFound.module.scss";
import { Viewport } from "@/types/enum";

type NotFoundProps = {
    componentParent?: string;
    viewport?: Viewport;
};

const NotFound = ({ componentParent, viewport = Viewport.Desktop }: NotFoundProps) => {
    useTimeoutSimple(() => {
        window.scrollTo({
            behavior: "instant",
            top: 0,
        });
    }, 200);

    return (
        <Layout mainBody={false} viewport={viewport}>
            <Head>
                <meta property="og:title" content="Page non trouvée - Erreur 404" key="title" />
                <title>Page non trouvée - Erreur 404</title>
            </Head>
            <div
                className={styles.me}
                data-component="NotFound"
                data-componentparent={componentParent}
            >
                <div className="body" id="main-content">
                    <h1 className={styles.title}>Oups! Page non trouvée - Erreur 404</h1>
                    <div className={styles.content}>
                        <h2 className={classNames(styles.bloc, styles.blocLeft)}>
                            Cette page est
                            <br />
                            dans les patates...
                            <br />
                            pilées!
                        </h2>
                        <img
                            className={styles.image}
                            height="600"
                            src="/images/error/patates_pilees.jpg"
                            alt="Erreur 404"
                        />
                    </div>
                </div>
                <ListeRelated title="Vous allez adorer ces recettes" />
            </div>
        </Layout>
    );
};

export default NotFound;
